<!--
  Simple image viewer for one series based on example at:
  https://github.com/dannyrb/cornerstone-vuejs-poc/blob/master/src/components/CornerstoneCanvas.vue
-->
<template>
  <div>
    <div ref="navBarDiv">
      <div>
        <b-navbar class="pl-1" type="dark" variant="dark">
          <b-navbar-nav>
            <b-dropdown :size="buttonSize" left title="Series" variant="info">
              <template #button-content>
                <b-icon icon="list-ul"></b-icon>
              </template>
              <b-dropdown-item v-for="s in seriesInfo" :key="s.series_uid" :disabled="(s.instances.length==0) || Boolean(isNonImageSeries(s.instances))" @click="handleSeriesChanged(s.series_uid)">
                <b-icon v-if="s.series_uid == seriesUid" icon="toggle-on" variant="info"/>
                <b-icon v-else icon="toggle-off" variant="secondary"/>
                {{s.modality}}:{{s.desc}}&nbsp;&nbsp;S:{{s.series_num}}&nbsp;&nbsp;#I:{{s.num_instances}}
              </b-dropdown-item>
            </b-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-badge class="ml-1" variant="info">{{seriesInfo.length}}</b-badge>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-button-group :size="buttonSize">
              <b-button class="ml-1" @click="handleAnnotations" variant="secondary" title="Toggle Annotations On/Off">
                <b-icon icon="stickies"></b-icon>
              </b-button>
              <b-button v-if="!isCineMode" class="ml-1" @click="handleCineMode" variant="secondary" title="Cine">
                <b-icon icon="play-fill"></b-icon>
              </b-button>
              <b-button v-if="isCineMode" class="ml-1" @click="handleCineMode" variant="primary" title="Cine">
                <b-icon icon="play-fill"></b-icon>
              </b-button>
              <b-dropdown class="ml-1" title="Left Mouse Function" right>
                <template #button-content>
                  <span v-if="toolLeftClick=='wwwc'" class="material-icons md-18">&#xe427;</span>
                  <b-icon v-if="toolLeftClick=='stackScroll'" icon="list"></b-icon>
                  <b-icon v-if="toolLeftClick=='zoom'" icon="search"></b-icon>
                  <span v-if="toolLeftClick=='pan'" class="material-icons md-18">&#xe89f;</span>
                  <span v-if="toolLeftClick=='length'" class="material-icons md-18">&#xe41c;</span>
                  <b-icon v-if="toolLeftClick=='angle'" icon="chevron-left"></b-icon>
                  <b-icon v-if="toolLeftClick=='ellipticalRoi'" icon="circle"></b-icon>
                  <b-icon v-if="toolLeftClick=='rectangleRoi'" icon="square"></b-icon>
                  <b-icon v-if="toolLeftClick=='dragProbe'" icon="record-circle"></b-icon>
                  <b-icon v-if="toolLeftClick=='magnify'" icon="circle-fill"></b-icon>
                </template>
                <b-dropdown-item-button @click="changeCornerstoneTool('wwwc')">
                  <span class="material-icons">&#xe427;</span> Levels
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('stackScroll')">
                  <b-icon icon="list"></b-icon> Stack Scroll
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('zoom')">
                  <b-icon icon="search"></b-icon> Zoom
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('magnify')">
                  <b-icon icon="circle-fill"></b-icon> Magnify
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('pan')">
                  <span class="material-icons">&#xe89f;</span> Pan
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('length')"> 
                  <span class="material-icons">&#xe41c;</span> Length
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('angle')"> 
                  <b-icon icon="chevron-left"></b-icon> Angle
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('ellipticalRoi')">
                  <b-icon icon="circle"></b-icon> ROI (Ellipse)
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('rectangleRoi')">
                  <b-icon icon="square"></b-icon> ROI (Rectangle)
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeCornerstoneTool('dragProbe')">
                  <b-icon icon="record-circle"></b-icon> Probe
                </b-dropdown-item-button>
              </b-dropdown>
              <b-dropdown class="ml-1" title="" right>
                <template #button-content><span class="material-icons md-18">&#xe1bd;</span></template>
                <b-dropdown-item-button @click="handleInvert">
                  <b-icon icon="circle-half"></b-icon> Invert
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="handleRotate">
                  <b-icon icon="arrow90deg-right"></b-icon> Rotate 90&deg;
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="handleFliph">
                  <b-icon icon="three-dots"></b-icon> Flip H
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="handleFlipv">
                  <b-icon icon="three-dots-vertical"></b-icon> Flip V
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="fullscreenSupported" @click="handleFullscreen">
                  <b-icon icon="arrows-fullscreen"></b-icon> Full Screen
                </b-dropdown-item-button>
              </b-dropdown>
              <b-dropdown class="ml-1" title="Favorites" right>
                <template #button-content>
                  <b-icon icon="star-fill"></b-icon>
                </template>
                <b-dropdown-item-button v-for="fav in wwwlFavorites" :key="fav.key" @click="handleWwwl(fav.window, fav.level)">
                  <span class="material-icons">&#xe427;</span> {{fav.description}}
                </b-dropdown-item-button>
              </b-dropdown>
              <b-button class="ml-1" @click="handleReset" variant="secondary" title="Reset">
                <b-icon icon="arrow-counterclockwise"></b-icon>
              </b-button>
            </b-button-group>
          </b-navbar-nav>
        </b-navbar>
      </div>
      <div>
        <b-navbar class="pl-1 text-white" type="dark" variant="dark" style="height:50px;min-height:50px;max-height:50px">
          <b-navbar-nav>
            <span>S:&nbsp;</span>
            <b-button-group :size="buttonSize">
              <b-button :disabled="seriesIPrevious<0" @click="handleSeriesIChanged(seriesIPrevious)" variant="secondary" title="Previous Series">
                <b-icon icon="arrow-left-circle-fill"></b-icon>
              </b-button>
              <b-button :disabled="seriesINext<0" @click="handleSeriesIChanged(seriesINext)" variant="secondary" title="Next Series">
                <b-icon icon="arrow-right-circle-fill"></b-icon>
              </b-button>
            </b-button-group>
          </b-navbar-nav>
          <b-navbar-nav v-if="!isCineMode">
            <span class="ml-2">I:&nbsp;</span>
            <b-form-spinbutton size="sm" inline wrap v-model="instanceIPlus1" min="1" :disabled="instanceInfo.length<2" :max="instanceInfo.length" @input="handleInstanceChanged">
            </b-form-spinbutton>
            <span v-if="numFrames>1" class="ml-2">F:&nbsp;</span>
            <b-form-spinbutton v-if="numFrames>1" size="sm" inline wrap v-model="frameIPlus1" min="1" :disabled="numFrames<2" :max="numFrames" @input="handleFrameChanged">
            </b-form-spinbutton>
          </b-navbar-nav>
          <b-navbar-nav class="ml-2 p-0" v-if="isCineMode">
            <b-button-group :size="buttonSize">
              <b-button @click="handleCineSkipFirst" variant="secondary" title="Skip to first image">
                <b-icon icon="skip-backward-fill"></b-icon>
              </b-button>
              <b-button @click="handleCinePrevious" variant="secondary" title="Previous image">
                <b-icon icon="skip-start-fill"></b-icon>
              </b-button>
              <b-button @click="handleCinePlayStop" variant="secondary" title="Play / Stop">
                <b-icon v-if="!isCinePlaying" icon="play-fill"></b-icon>
                <b-icon v-if="isCinePlaying" icon="stop-fill"></b-icon>
              </b-button>
              <b-button @click="handleCineNext" variant="secondary" title="Next image">
                <b-icon icon="skip-end-fill"></b-icon>
              </b-button>
              <b-button @click="handleCineSkipLast" variant="secondary" title="Skip to last image">
                <b-icon icon="skip-forward-fill"></b-icon>
              </b-button>
            </b-button-group>
            <b-dropdown class="ml-1" right>
              <template #button-content>
                <span v-if="cineFpsMiss<=1" class="cine-fps-label">{{cineFps}} fps</span>
                <span v-if="cineFpsMiss>1" class="cine-fps-label text-yellow">{{cineFps}} fps</span>
              </template>
              <b-form-spinbutton size="sm" v-model="cineFps" min="1" max="90" @input="cineFpsMiss=0">
              </b-form-spinbutton>
              <b-dropdown-text v-if="cineFpsMiss>1" class="cine-fps-label"><b-icon icon="exclamation-triangle-fill" ></b-icon> Actual fps lower</b-dropdown-text>
            </b-dropdown>
          </b-navbar-nav>
        </b-navbar>
      </div>
    </div>
    <!-- WRAPPER -->
    <div class="image-canvas-wrapper mt-2"
      id="image-canvas-wrapper"
      oncontextmenu="return false"
      unselectable='on'
      onselectstart='return false;'
      onmousedown='return false;'
      :style="displayDivStyle">
      <!-- DICOM CANVAS -->
      <div id="image-canvas" class="image-canvas" oncontextmenu="return false"></div>
      <!-- ANNOTATIONS -->
      <div v-if="loadingMsg!=''" class="loading-div" :style="loadingDivStyle"><h5>{{loadingMsg}}</h5></div>
      <div v-if="annotations" class="annotations-div-tc"><span v-html="annotationsTC"/></div>
      <div v-if="annotations" class="annotations-div-cl"><span v-html="annotationsCL"/></div>
      <div v-if="annotations" class="annotations-div-tl small" :style="annotationsStyle"><span v-html="annotationsTL"/></div>
      <div v-if="annotations" class="annotations-div-tlb small"><span v-html="annotationsTL"/></div>
      <div v-if="annotations" class="annotations-div-tr small" :style="annotationsStyle"><span v-html="annotationsTR"/></div>
      <div v-if="annotations" class="annotations-div-trb small"><span v-html="annotationsTR"/></div>
      <div v-if="annotations" class="annotations-div-bl small" :style="annotationsStyle"><span v-html="annotationsBL"/></div>
      <div v-if="annotations" class="annotations-div-blb small"><span v-html="annotationsBL"/></div>
      <div v-if="annotations" class="annotations-div-br small" :style="annotationsStyle"><span v-html="annotationsBR"/></div>
      <div v-if="annotations" class="annotations-div-brb small"><span v-html="annotationsBR"/></div>
    </div>
  </div>
</template>
<script>
import dicomweb from '../common/dicomweb'
import nonImageSopClassUids from '../common/nonImageSopClassUids.json'
import webServices from '../common/webServices'
import webServicesImageLoader from '../common/webServicesImageLoader'

// Setup Cornerstone
//
import $ from 'jquery'
import Hammer from 'hammerjs'
import * as cornerstone from 'cornerstone-core'
import * as cornerstoneMath from 'cornerstone-math'
import * as cornerstoneTools from 'cornerstone-tools'
import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader'

//cornerstone.external.$ = $
//cornerstoneTools.external.$ = $
cornerstoneTools.external.Hammer = Hammer
cornerstoneTools.external.cornerstone = cornerstone
cornerstoneTools.external.cornerstoneMath = cornerstoneMath
//cornerstoneTools.orientationMarkers.setConfiguration({ drawAllMarkers: true }); // false == top/left only
//cornerstoneWebImageLoader.external.$ = $
cornerstoneWebImageLoader.external.cornerstone = cornerstone
cornerstoneWebImageLoader.external.cornerstoneMath = cornerstoneMath

cornerstone.registerImageLoader('blob', cornerstoneWebImageLoader.loadImage)

export default {
  name: 'viewerLiteSeries',
  components: {
  },
  data() {
    return {
      buttonSize: "sm",
      cineFps: 15,
      cineFpsMiss: 0,
      cineNextTimeout: 0,
      cineTimeoutID: null,
      displayHeight: 100,
      frameI: 0,
      instanceI: 0,
      instanceIPlus1: 1,
      instanceInfo: [],
      isCineMode: false,
      isCinePlaying: false,
      isInitLoad: true,
      loadingMsg: "",
      imageIdCache: {},
      seriesI: 0,
      seriesUid: null,
      scrollEndTs: 0,

      // Viewport settings
      //
      toolLeftClick: "wwwc",
      annotations: true,
      pixelValueString: "",
      tcString: "",
      clString: "",
      invert: false,
      rotation: 0,
      hflip: false,
      vflip: false,
      scale: -1.0,
      translation: {x:0, y:0},
      ww: 256,
      wl: 128,
      wwWlNeedsInit: true,
      num_bytes_blob: 0
    };
  },
  props: {
    "active": Boolean,
    "seriesInfo": Array
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    // Add MetaData Provider: https://docs.cornerstonejs.org/concepts/metadata-providers.html
    //
    cornerstone.metaData.addProvider(this.metaDataProvider)
    this.handleResize()
  },
  beforeDestroy () {
    this.seriesUid = null
    if (!this.isInitLoad) {
      let canvas = document.getElementById("image-canvas")
      $(canvas).off()
    }
  },
  computed: {
    entry() {
      const entry = this.$store.getters.worklistEntryForStudy(this.studyUid)
      if (entry != null) {
        return entry
      }
      else {
        return webServices.getEmptyWorklistEntry()
      }
    },
    locale() {
      return this.$store.state.locale
    },
    numFrames() {
      var n = 0
      if (this.instanceI < this.instanceInfo.length) {
        n = this.instanceInfo[this.instanceI].num_frames
      }
      return n
    },
    seriesINext() {
      let sIN = -1
      let sI = this.seriesI
      while (sI < this.seriesInfo.length - 1) {
        sI++
        if ((this.seriesInfo[sI].instances.length > 0) && !this.isNonImageSeries(this.seriesInfo[sI].instances)) {
          sIN = sI
          break
        }
      }
      return sIN
    },
    seriesIPrevious() {
      let sIP = -1
      let sI = this.seriesI
      while (sI > 0) {
        sI--
        if ((this.seriesInfo[sI].instances.length > 0) && !this.isNonImageSeries(this.seriesInfo[sI].instances)) {
          sIP = sI
          break
        }
      }
      return sIP
    },
    studyUid() {
      return this.$store.state._selectedStudyUid
    },
    annotationsStyle() {
      var color = "#85aed3"
      if (this.$store.state.worklistViewers.lite_annotation_color != '') {
        color = this.$store.state.worklistViewers.lite_annotation_color
      }
      return `color: ${color};`
    },
    annotationsTC() {
      return this.tcString // +TODO+ position indicator
    },
    annotationsCL() {
      return this.clString // +TODO+ position indicator
    },
    annotationsTL() {
      var a = this.entry.patient_name+"<br/>"+this.entry.patient_id
      if (this.toolLeftClick == 'dragProbe') {
        a += '<br>' + this.pixelValueString
      }
      return a
    },
    annotationsBL() {
      var a = "Ser: ---<br/>Img: ---<br/>--- x ---"
      if ((this.seriesI >= 0) && (this.seriesI < this.seriesInfo.length))
      {
        const s = this.seriesInfo[this.seriesI]
        a = "Ser: "+s.series_num+"<br/>Img: "
        if (this.instanceI < this.instanceInfo.length) {
          const i = this.instanceInfo[this.instanceI]
          a += i.instance_num + " "
          a += (parseInt(this.instanceI) + 1) + '/' + this.instanceInfo.length
          if (i.num_frames > 1) {
            a += "<br/>Frame: " + (parseInt(this.frameI) + 1) + '/' + i.num_frames
          }
          a += "<br/>" + i.cols + " x " + i.rows
        }
        else {
          a += "---<br/>--- x ---"
        }
      }
      return a
    },
    annotationsTR() {
      var a = "---<br/>"
      if ((this.seriesI >= 0) && (this.seriesI < this.seriesInfo.length))
      {
        const s = this.seriesInfo[this.seriesI]
        a = (s.desc=="") ? "---<br/>" : s.desc+"<br/>"
      }
      a += (this.entry.study_date_time==null) ? '---' : new Date(this.entry.study_date_time).toLocaleString(this.locale)
      return a
    },
    annotationsBR() {
      //Zoom:, W: L:, Lossless / Uncompressed
      var a = 'Zoom: ---<br/>---' 
      if ((this.instanceI < this.instanceInfo.length) && (this.num_bytes_blob > 0)) {
        a = 'Zoom: ' + Math.round(100.0 * this.scale) + '%<br/>'
        if (this.instanceInfo[this.instanceI].lossy) {
          a += '<font color="yellow"><b-icon icon="exclamation-triangle-fill" color="yellow"></b-icon></font> Lossy'
        }
        else {
          a += 'Lossless ' + (this.instanceInfo[this.instanceI].num_bytes / this.num_bytes_blob).toFixed(1) + ':1 '
        }
      }
      a += "<br/>W: "+Math.round(this.ww)+" L: "+Math.round(this.wl)
      a += "<br/>INVESTIGATIONAL USE ONLY"
      return a
    },
    displayDivStyle() {
      return "height: "+this.displayHeight+"px; min-height: "+this.displayHeight+"px; max-height: "+this.displayHeight+"px;"
    },
    fullscreenSupported() {
      let supported = false
      if (typeof document.fullscreenElement !== "undefined") {
        supported = true
      } 
      else if (typeof document.webkitFullscreenElement !== "undefined") {
        supported = true
      }
      return supported
    },
    loadingDivStyle() {
      let style = "opacity: "
      style += (this.loadingMsg == '') ? '0.0;' : '1.0;'
      return style
    },
    wwwlFavorites() {
      return this.$store.state.wwwlFavorites
    }
  },
  watch: {
    entry(newVal/*, oldVal*/) {
      if (newVal.study_uid != this.studyUid) {
        this.imageIdCache = {}
        this.loadingMsg = "Loading ..."
      }
    },
    active: {
      immediate: true,
      handler(/*newVal, oldVal*/) {
        this.seriesUid = null
        this.stopCine()
      }
    },
    seriesInfo: {
      immediate: true,
      handler(newVal/*, oldVal*/) {
        if (newVal.length > 0) {
          this.loadingMsg = "Loading ..."
          this.resetViewportSettings()
          this.seriesI = 0
          for (var s = 0; s < newVal.length; s++) {
            if ((newVal[s].instances.length > 0) && (!this.isNonImageSeries(newVal[s].instances))) {
              this.seriesI = s
              break
            }
          }
          this.seriesUid = newVal[this.seriesI].series_uid
          this.instanceI = 0
          this.instanceIPlus1 = 1
          this.frameI = 0
          this.frameIPlus1 = 1
          this.num_bytes_blob = 0
          this.instanceInfo = this.seriesInfo[this.seriesI].instances
          this.getPixelData()
        }
        else {
          this.seriesI = -1
          this.seriesUid = null
        }
      }
    }
  },
  methods: {
    isNonImage(sopClassUid) {
      return (nonImageSopClassUids[sopClassUid] !== undefined)
    },
    isNonImageSeries(instances) {
      let nonImageSeries = true
      for (var i = 0; i < instances.length; i++) {
        nonImageSeries &= this.isNonImage(instances[i].sop_class_uid)
      }
      return nonImageSeries
    },
    resetViewportSettings() {
      this.stopCine()
      this.invert = false
      this.rotation = 0
      this.hflip = false
      this.vflip = false
      this.scale = -1.0
      this.translation = {x:0, y:0}
      this.wwWlNeedsInit = true
      if (this.instanceInfo.length > 0) {
        for (var i=0; i < this.instanceInfo.length; i++) {
          this.instanceInfo[i].reset = true
        }
      }
    },
    stopCine() {
      this.isCinePlaying = false
      if (this.cineTimeoutID != null) {
        clearTimeout(this.cineTimeoutID)
        this.cineTimeoutID = null
      }
      this.cineFpsMiss = 0
      this.nextCineTimeout = 0
    },
    handleCine() {
      if (this.isCinePlaying && (this.seriesUid != null)) {
        let now = Date.now()
        let frameInterval = Math.round(1000.0 / this.cineFps)
        let delay = (this.cineNextTimeout == 0) ? 0 : now - this.cineNextTimeout
        let timeout = frameInterval - delay
        if (timeout < 10) { 
          timeout = 10
          this.cineFpsMiss = Math.min(1000, this.cineFpsMiss + Math.ceil(delay / frameInterval))
        }
        else {
          this.cineFpsMiss = Math.max(0, this.cineFpsMiss - 1)
        }
        this.cineNextTimeout = now + timeout
        this.cineTimeoutID = setTimeout(() => {
          if (this.isCinePlaying && (this.seriesUid != null)) {
            this.handleCineNext()
            this.handleCine()
          }
        }, timeout)
      }
    },
    handleAnnotations() {
      this.annotations = !this.annotations
    },
    handleCineMode() {
      if (this.isCinePlaying) {
        this.stopCine()
      }
      this.isCineMode = !this.isCineMode
    },
    handleCinePlayStop() {
      this.isCinePlaying = !this.isCinePlaying
      if (this.isCinePlaying) {
        this.handleCine()
      }
      else {
        this.stopCine()
      }
    },
    handleCineSkipFirst() {
      if (this.numFrames > 1) {
        this.frameIPlus1 = 1
        this.handleFrameChanged()
      }
      else {
        this.instanceIPlus1 = 1
        this.handleInstanceChanged()
      }
    },
    handleCineSkipLast() {
      if (this.numFrames > 1) {
        this.frameIPlus1 = this.numFrames
        this.handleFrameChanged()
      }
      else {
        this.instanceIPlus1 = this.instanceInfo.length
        this.handleInstanceChanged()
      }
    },
    handleCineNext() {
      if (this.numFrames > 1) {
        this.frameIPlus1 = (this.frameIPlus1 + 1 > this.numFrames) ? 1 : (this.frameIPlus1 + 1)
        this.handleFrameChanged()
      }
      else {
        this.instanceIPlus1 = (this.instanceIPlus1 + 1 > this.instanceInfo.length) ? 1 : (this.instanceIPlus1 + 1)
        this.handleInstanceChanged()
      }
    },
    handleCinePrevious() {
      if (this.numFrames > 1) {
        this.frameIPlus1 = (this.frameIPlus1 - 1 < 1) ? this.numFrames : (this.frameIPlus1 - 1)
        this.handleFrameChanged()
      }
      else {
        this.instanceIPlus1 = (this.instanceIPlus1 - 1 < 1) ? this.instanceInfo.length : (this.instanceIPlus1 - 1)
        this.handleInstanceChanged()
      }
    },
    handleFliph() {
      this.hflip = !this.hflip
      this.updateImageDisplayed()
    },
    handleFlipv() {
      this.vflip = !this.vflip
      this.updateImageDisplayed()
    },
    handleFullscreen() {
      try {
        if (this.fullscreenSupported) {
          let inFullscreen = false
          if ((typeof document.fullscreenElement !== "undefined") && (document.fullscreenElement != null)) {
            inFullscreen = true
          } 
          else if ((typeof document.webkitFullscreenElement !== "undefined") && (document.webkitFullscreenElement != null)) {
            inFullscreen = true
          }
            
          let canvas = document.getElementById("image-canvas-wrapper")
          if (inFullscreen) {
            this.$log.debug("EXIT FULL")
            if (canvas.requestFullscreen) {
              document.exitFullscreen();
            } else if (canvas.webkitRequestFullscreen) { /* Safari */
              document.webkitExitFullscreen();
            }
          }
          else {
            this.$log.debug("ENTER FULL")
            if (canvas.requestFullscreen) {
              canvas.requestFullscreen();
            } else if (canvas.webkitRequestFullscreen) { /* Safari */
              canvas.webkitRequestFullscreen();
            }
          }
        }
      }
      catch(err) {
        this.$log.warn(`Unable to handle fullscreen request.: ${err.message}`)
      }
    },
    handleInvert() {
      this.invert = !this.invert
      this.updateImageDisplayed()
    },
    handleRotate() {
      this.rotation += 90
      this.updateImageDisplayed()
    },
    handleReset() {
      this.resetViewportSettings()
      this.updateImageDisplayed()
    },
    handleWwwl(window, level) {
      this.$log.debug(`Using Favorite W=${window} L=${level}`)
      this.ww = window
      this.wl = level
      this.updateImageDisplayed()
    },
    metaDataProvider(type, imageId) {
      // Cornerstone-compatible MetaData Provider: https://docs.cornerstonejs.org/concepts/metadata-providers.html
      //
      var metadata = {}
      if (type === 'imagePlaneModule') {
        const dicomMetadata = this.instanceInfo[this.instanceI].metadata
        const imageIdCacheKey = this.instanceInfo[this.instanceI].instance_uid + "_f" + this.frameI
        let image = this.imageIdCache[imageIdCacheKey]
        if ((dicomMetadata != null) && (image !== undefined) && (image.imageId == imageId)) {
          metadata = {
            frameOfReferenceUID: dicomweb.getValue(dicomMetadata, "00200052"),
            rows: image.rows,
            columns: image.columns,
            rowCosines: {
                x: dicomweb.getDecimalValue(dicomMetadata, "00200037", 1, 0),
                y: dicomweb.getDecimalValue(dicomMetadata, "00200037", 0, 1),
                z: dicomweb.getDecimalValue(dicomMetadata, "00200037", 0, 2)
            },
            columnCosines: {
                x: dicomweb.getDecimalValue(dicomMetadata, "00200037", 0, 3),
                y: dicomweb.getDecimalValue(dicomMetadata, "00200037", 1, 4),
                z: dicomweb.getDecimalValue(dicomMetadata, "00200037", 0, 5)
            },
            imagePositionPatient: {
                x: dicomweb.getDecimalValue(dicomMetadata, "00200032", 0, 0),
                y: dicomweb.getDecimalValue(dicomMetadata, "00200032", 0, 1),
                z: dicomweb.getDecimalValue(dicomMetadata, "00200032", 0, 2)
            },
            rowPixelSpacing: image.rowPixelSpacing,
            columnPixelSpacing: image.columnPixelSpacing
          }
        }
      }
      return metadata
    },
    async getPixelData() {
      const loadingEntry = this.entry
      const loadingSeriesUid = this.seriesUid
      const loadingInstanceInfo = this.instanceInfo
      var instanceI = 0
      while((loadingSeriesUid == this.seriesUid) && (instanceI < loadingInstanceInfo.length)) {
        let frameI = 0
        loadingInstanceInfo[instanceI].metadata = await dicomweb.getInstanceMetadata(loadingEntry, loadingSeriesUid, loadingInstanceInfo[instanceI].instance_uid)
        while((loadingSeriesUid == this.seriesUid) && (frameI < loadingInstanceInfo[instanceI].num_frames)) {
          try {
            let imageIdCacheKey = loadingInstanceInfo[instanceI].instance_uid + "_f" + frameI
            if (this.imageIdCache[imageIdCacheKey] !== undefined) {
              this.updateImageDisplayed()
            }
            else {
              const quality = this.$store.state.worklistViewers.lite_quality
              let frame = frameI + 1

              // Photomoetric intepretation determine how pixel data should be loaded. RGB types can get pre-rendered
              // image from DCM4CHEE directly. Monochrome types need raw pixel data (compressed based on quality factor).
              //
              const photometricInterp = dicomweb.getValue(loadingInstanceInfo[instanceI].metadata, "00280004")
              const lossy = dicomweb.getValue(loadingInstanceInfo[instanceI].metadata, "00282110")
              if ((photometricInterp === "MONOCHROME1") || (photometricInterp === "MONOCHROME2")) {
                const image = await webServicesImageLoader.loadImage(imageIdCacheKey, loadingInstanceInfo[instanceI].metadata, loadingEntry, loadingSeriesUid, loadingInstanceInfo[instanceI].instance_uid, frame, quality)
                loadingInstanceInfo[instanceI].num_bytes_blob[frameI] = image.sizeInBytesCompressed
                loadingInstanceInfo[instanceI].lossy = (parseInt(lossy) == 1) ? true : false
                this.imageIdCache[imageIdCacheKey] = image
                if (this.loadingMsg != '') {
                  this.updateImageDisplayed()
                }
              }
              else {
                const response = await dicomweb.getPixelDataRendered(loadingEntry, loadingSeriesUid, loadingInstanceInfo[instanceI].instance_uid, frame, quality)
                loadingInstanceInfo[instanceI].num_bytes_blob[frameI] = response.byteLength
                loadingInstanceInfo[instanceI].lossy = true
                let _self = this
                const pixelDataBlob = new Blob([response])
                let url = URL.createObjectURL(pixelDataBlob)
                cornerstone.loadAndCacheImage(url).then(function(imageId) {
                  try {
                    _self.imageIdCache[imageIdCacheKey] = imageId
                    if (_self.loadingMsg != '') {
                      _self.updateImageDisplayed()
                    }
                  }
                  catch(e) {
                    _self.$log.warn("cornerstone loadAndCacheImage handler err: "+e.message)
                  }
                })
              }
            }
          }
          catch(e) {
            this.$log.warn("unable to read pixel data for instance_uid="+loadingInstanceInfo[instanceI].instance_uid+" err="+e.message)
            this.imageIdCache[loadingInstanceInfo[instanceI].instance_uid + "_f" + frameI] = false
            this.loadingMsg = "Image Not Available"
          }

          frameI++
        }
        instanceI++
      }
      this.$log.debug("DONE: instanceI="+instanceI+" loadingSeriesUid="+loadingSeriesUid+" this.seriesUid="+this.seriesUid)
    },
    handleResize() {
      let inFullscreen = false
      if ((typeof document.fullscreenElement !== "undefined") && (document.fullscreenElement != null)) {
        inFullscreen = true
      } 
      else if ((typeof document.webkitFullscreenElement !== "undefined") && (document.webkitFullscreenElement != null)) {
        inFullscreen = true
      }
      let wh = (window.outerHeight > window.innerHeight) ? window.innerHeight : window.outerHeight
      let height = (wh - 195);
      this.displayHeight = inFullscreen ? wh : height
      if (!this.isInitLoad) {
        try {
          let canvas = document.getElementById("image-canvas")
          cornerstone.resize(canvas, true)
          let viewport = cornerstone.getViewport(canvas)
          this.scale = viewport.scale
          this.translation.x = viewport.translation.x
          this.translation.y = viewport.translation.y
        }
        catch {
          // ignore
        }
      }
    },
    handleFrameChanged(/*event*/) {
      this.frameI = this.frameIPlus1 - 1
      this.updateImageDisplayed()
    },
    handleInstanceChanged(/*event*/) {
      this.instanceI = this.instanceIPlus1 - 1
      this.frameI = 0
      this.frameIPlus1 = 1
      this.num_bytes_blob = 0
      this.updateImageDisplayed()
    },
    handleSeriesIChanged(seriesI) {
      this.handleSeriesChanged(this.seriesInfo[seriesI].series_uid)
    },
    handleSeriesChanged(seriesUid) {
      this.seriesUid = seriesUid
      this.seriesI = -1
      for (var i=0; i<this.seriesInfo.length; i++) {
        if (this.seriesInfo[i].series_uid == seriesUid) {
          this.loadingMsg = "Loading ..."
          this.seriesI = i
          this.instanceI = 0
          this.instanceIPlus1 = 1
          this.frameI = 0
          this.frameIPlus1 = 1
          this.num_bytes_blob = 0
          this.instanceInfo = this.seriesInfo[i].instances
          this.resetViewportSettings()
          this.getPixelData()

          break
        }
      }
    },
    handleCornerstoneToolEvent(event, stackScroll) {
      // Handling stackScroll outside cornerstone tools, as stack state isn't being set.
      //
      try {
        if (stackScroll) {
          // scrollEndTs controls when to handle next stack scroll event. Made farther into future
          // for smaller drag motions (finer control).
          //
          if (!this.isCinePlaying && (this.scrollEndTs == 0)) {
            if (event.detail.deltaPoints.image.y < 0) {
              this.scrollEndTs = Date.now() - (200.0 / event.detail.deltaPoints.image.y)
              this.handleCineNext()
            }
            else {
              this.scrollEndTs = Date.now() + (200.0 / event.detail.deltaPoints.image.y)
              this.handleCinePrevious()
            }
          }
        }
        else {
          this.scale = event.detail.viewport.scale
          this.translation.x = event.detail.viewport.translation.x
          this.translation.y = event.detail.viewport.translation.y
          this.wl = event.detail.viewport.voi.windowCenter
          this.ww = event.detail.viewport.voi.windowWidth
          if (this.toolLeftClick == 'dragProbe') {
            try {
              let x = Math.round(event.detail.currentPoints.image.x)
              let y = Math.round(event.detail.currentPoints.image.y)
              if ((x >= 0) && (x < event.detail.image.columns) && (y >= 0) && (y < event.detail.image.rows)) {
                this.pixelValueString = "(" + x + ", " + y + ")"
                if (!event.detail.image.color) {
                  let i = (event.detail.image.columns * y) + x
                  let sp = event.detail.image.pixelData[i]
                  let mo = (event.detail.image.slope * sp) + event.detail.image.intercept
                  mo = +(Math.round(mo + "e+2")  + "e-2") // two decimal precision
                  this.pixelValueString += "<br/>SP: " + sp + " MO: " + mo
                }
              }
              else {
                this.pixelValueString = ""
              }
            }
            catch (err) {
              this.$log.debug("dragProbe pixel value error: "+err.message)
              this.pixelValueString = ""
            }
          }
          else {
            this.pixelValueString = ""
          }
        }
      }
      catch(err) {
        this.$log.debug(`handleCornerstoneToolEvent: ignoring error ${err.message}`)
      }
    },
    handleCornerstoneToolMouseEvent(event) {
      let stackScroll = ((event.detail.which == 1) && (this.toolLeftClick == 'stackScroll'))
      this.handleCornerstoneToolEvent(event, stackScroll)
    },
    handleCornerstoneToolTouchEvent(event) {
      let stackScroll = (this.toolLeftClick == 'stackScroll')
      this.handleCornerstoneToolEvent(event, stackScroll)
    },
    handleCornerstoneToolMouseWheel(event) {
      if (!this.isCinePlaying) {
        if (event.detail.direction < 0) {
          this.handleCineNext()
        }
        else {
          this.handleCinePrevious()
        }
      }
    },
    handleCornerstoneToolMultiTouchDrag(event) {
      this.handleCornerstoneToolEvent(event, false)
    },
    changeCornerstoneTool(tool) {
      try {
        // Deactivate current tool and activate requested tool for left mouse and touch actions.
        //
        var element = document.getElementById("image-canvas")
        switch(this.toolLeftClick) {
          case "angle":
            cornerstoneTools.simpleAngle.deactivate(element, 1)
            cornerstoneTools.simpleAngleTouch.deactivate(element)
            break
          case "dragProbe":
            cornerstoneTools.dragProbe.deactivate(element, 1);
            cornerstoneTools.dragProbeTouch.deactivate(element);
            break
          case "ellipticalRoi":
            cornerstoneTools.ellipticalRoi.deactivate(element, 1)
            cornerstoneTools.ellipticalRoiTouch.deactivate(element)
            break
          case "length":
            cornerstoneTools.length.deactivate(element, 1)
            cornerstoneTools.lengthTouch.deactivate(element)
            break
          case "magnify":
            cornerstoneTools.magnify.deactivate(element, 1)
            cornerstoneTools.magnifyTouchDrag.deactivate(element)
            break
          case "pan":
            cornerstoneTools.pan.deactivate(element, 1)
            cornerstoneTools.panTouchDrag.deactivate(element)
            break
          case "rectangleRoi":
            cornerstoneTools.rectangleRoi.deactivate(element, 1)
            cornerstoneTools.rectangleRoiTouch.deactivate(element)
            break
          case "stackScroll":
            cornerstoneTools.stackScroll.deactivate(element, 1)
            cornerstoneTools.stackScrollTouchDrag.deactivate(element)
            break
          case "wwwc":
            cornerstoneTools.wwwc.deactivate(element, 1)
            cornerstoneTools.wwwcTouchDrag.deactivate(element)
            break
          case "zoom":
            cornerstoneTools.zoom.deactivate(element, 1)
            cornerstoneTools.zoomTouchDrag.deactivate(element)
            break
          default:
            break
        }
        switch(tool) {
          case "angle":
            cornerstoneTools.simpleAngle.activate(element, 1)
            cornerstoneTools.simpleAngleTouch.activate(element)
            this.toolLeftClick = tool
            break
          case "dragProbe":
            cornerstoneTools.dragProbe.activate(element, 1);
            cornerstoneTools.dragProbeTouch.activate(element);
            this.toolLeftClick = tool
            break
          case "ellipticalRoi":
            cornerstoneTools.ellipticalRoi.activate(element, 1)
            cornerstoneTools.ellipticalRoiTouch.activate(element)
            this.toolLeftClick = tool
            break
          case "length":
            cornerstoneTools.length.activate(element, 1)
            cornerstoneTools.lengthTouch.activate(element)
            this.toolLeftClick = tool
            break
          case "magnify":
            cornerstoneTools.magnify.activate(element, 1)
            cornerstoneTools.magnifyTouchDrag.activate(element)
            this.toolLeftClick = tool
            break
          case "pan":
            cornerstoneTools.pan.activate(element, 1)
            cornerstoneTools.panTouchDrag.activate(element)
            this.toolLeftClick = tool
            break
          case "rectangleRoi":
            cornerstoneTools.rectangleRoi.activate(element, 1)
            cornerstoneTools.rectangleRoiTouch.activate(element)
            this.toolLeftClick = tool
            break
          case "stackScroll":
            // Bug prevents both mouse and touch from being active (https://github.com/cornerstonejs/cornerstoneTools/issues/338).
            //
            //cornerstoneTools.stackScroll.activate(element, 1)
            cornerstoneTools.stackScrollTouchDrag.activate(element)
            this.toolLeftClick = tool
            break
          case "wwwc":
            cornerstoneTools.wwwc.activate(element, 1)
            cornerstoneTools.wwwcTouchDrag.activate(element)
            this.toolLeftClick = tool
            break
          case "zoom":
            cornerstoneTools.zoom.activate(element, 1)
            cornerstoneTools.zoomTouchDrag.activate(element)
            this.toolLeftClick = tool
            break
          default:
            this.$log.warn("Invalid tool requested, requested tool="+tool)
            this.changeCornerstoneTool(this.toolLeftClick)
            break
        }
      }
      catch(err) {
        this.$log.error("Error while changing cornerstoneTools: "+err.message)
      }
    },
    initCornerstoneTools() {
      this.isInitLoad = false
      try {
        var element = document.getElementById("image-canvas")
        cornerstoneTools.orientationMarkers.enable(element);

        // We need to enable each way we'd like to be able to receive
        // and respond to input (mouse, touch, scrollwheel, etc.)
        //
        // Ref: https://github.com/cornerstonejs/cornerstoneTools/tree/2.4.0/examples
        //
        cornerstoneTools.mouseInput.enable(element)
        cornerstoneTools.touchInput.enable(element)
        cornerstoneTools.mouseWheelInput.enable(element);

        // Activate mouse tools we'd like to use
        cornerstoneTools.wwwc.activate(element, 1) // left click
        cornerstoneTools.pan.activate(element, 2) // middle click
        cornerstoneTools.zoom.activate(element, 4); // right click
        cornerstoneTools.stackScrollWheel.activate(element);

        // Activate Touch / Gesture tools we'd like to use
        cornerstoneTools.wwwcTouchDrag.activate(element) // - Drag
        cornerstoneTools.zoomTouchPinch.activate(element) // - Pinch
        cornerstoneTools.panMultiTouch.activate(element) // - Multi (x2)

        //element.addEventListener("cornerstonetoolsmouseup", this.handleCornerstoneToolMouseEvent)
        element.addEventListener("cornerstonetoolsmousedown", this.handleCornerstoneToolMouseEvent)
        element.addEventListener("cornerstonetoolsmousedrag", this.handleCornerstoneToolMouseEvent)
        element.addEventListener("cornerstonetoolstouchstart", this.handleCornerstoneToolTouchEvent)
        //element.addEventListener("cornerstonetoolstouchend", this.handleCornerstoneToolTouchEvent)
        element.addEventListener("cornerstonetoolstouchdrag", this.handleCornerstoneToolTouchEvent)
        element.addEventListener("cornerstonetoolstouchpinch", this.handleCornerstoneToolTouchEvent)
        element.addEventListener("cornerstonetoolsmousewheel", this.handleCornerstoneToolMouseWheel)
        element.addEventListener("cornerstonetoolsmultitouchdrag", this.handleCornerstoneToolMultiTouchDrag)
        element.addEventListener("cornerstonetoolsmousedoubleclick", this.handleFullscreen)
        element.addEventListener("cornerstonetoolsdoubletap", this.handleFullscreen)
      }
      catch(err) {
        this.$log.error("Error while initializing cornerstoneTools: "+err.message)
      }
    },
    updateImageDisplayed() {
      try {
        this.pixelValueString = ''
        let instanceUidFrame = this.instanceInfo[this.instanceI].instance_uid + "_f" + this.frameI
        let num_bytes_blob = this.instanceInfo[this.instanceI].num_bytes_blob[this.frameI]
        let image = this.imageIdCache[instanceUidFrame]
        if (image === undefined) {
          // Image has not been loaded from server.
          //
          this.loadingMsg = "Loading ..."
          this.scrollEndTs = 0
        }
        else if (image === false) {
          // Image failed to be rendered by server or loaded by cornerstone.
          //
          this.loadingMsg = "Image Not Available"
          if (this.isNonImage(this.instanceInfo[this.instanceI].sop_class_uid)) {
            this.loadingMsg += " (" + nonImageSopClassUids[this.instanceInfo[this.instanceI].sop_class_uid] + ")"
          }
          this.scrollEndTs = 0
        }
        else {
          let canvas = document.getElementById("image-canvas")
          cornerstone.enable(canvas);

          let _self = this
          let resetToolState = this.instanceInfo[this.instanceI].reset
          this.instanceInfo[this.instanceI].reset = false
          try {
            _self.loadingMsg = "";
            let viewport = cornerstone.getDefaultViewportForImage(canvas, image);
            viewport.rotation = _self.rotation;
            viewport.hflip = _self.hflip;
            viewport.vflip = _self.vflip;
            if (_self.wwWlNeedsInit) {
              _self.wl = image.windowCenter;
              _self.ww = image.windowWidth;
              _self.invert = image.invert;
              _self.wwWlNeedsInit = false
            }
            viewport.invert = _self.invert;
            viewport.voi.windowCenter = _self.wl;
            viewport.voi.windowWidth = _self.ww;
            viewport.scale = Math.abs(_self.scale);
            viewport.translation.x = _self.translation.x
            viewport.translation.y = _self.translation.y
            cornerstone.displayImage(canvas, image, viewport);
            if (_self.scale == -1.0) {
              cornerstone.fitToWindow(canvas);
              viewport = cornerstone.getViewport(canvas)
              _self.scale = viewport.scale;
            }
            _self.num_bytes_blob = num_bytes_blob
            
            if (_self.isInitLoad) {
              _self.initCornerstoneTools()
            }

            // If reset flag set, remove annotations added by tools.
            //
            if (resetToolState) {
              try {
                var toolStateManager = cornerstoneTools.globalImageIdSpecificToolStateManager
                toolStateManager.clear(canvas)
              }
              catch(err) {
                _self.$log.error("Error clearing cornerstone tools state: "+err.message)
              }
            }
          }
          catch(e) {
            _self.$log.warn("cornerstone loadImage handler err: "+e.message)
          }
          finally {
            const timeout = _self.scrollEndTs - Date.now()
            if (timeout > 0) {
              setTimeout(() => {
                _self.scrollEndTs = 0
              }, timeout)
            }
            else {
              _self.scrollEndTs = 0
            }
          }
        }
      }
      catch(err) {
        // May not be loaded. This method will be called again after each slice/frame is loaded.
        // For now set src to null so loading message displays.
        //
        //this.$log.warn("update canvas err: "+err.message)
        this.loadingMsg= "Loading ...";
        this.scrollEndTs = false
      }
    },
  }
};
</script>
<style scoped>
.cine-fps {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}
.cine-fps-label {
  font-size: 12px;
}
.md-18 {
  font-size: 18px;
}
.text-yellow {
  color: yellow;
}
.display-div {
  top: 0px; 
  left: 0px; 
  width: 100%;
}
.image-canvas-wrapper {
  border: 1px solid #1c8cb6;
  color: white; 
  background-color: black;
  position: relative;
  width: 100%;
  z-index: 1;
}
.image-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.loading-div {
  background-color: #000000;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  color: #85aed3;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.annotations-div-tl {
  pointer-events: none;
  position: absolute;
  padding: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  text-align: left;
  align-items: left;
}
.annotations-div-tlb {
  pointer-events: none;
  position: absolute;
  padding: 6px;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  z-index: 2;
  text-align: left;
  align-items: left;
}
.annotations-div-tc {
  pointer-events: none;
  position: absolute;
  padding: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  color: #f0f0f0;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
}
.annotations-div-cl {
  pointer-events: none;
  position: absolute;
  padding: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  color: #f0f0f0;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;
}
.annotations-div-tr {
  pointer-events: none;
  position: absolute;
  padding: 5px;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  text-align: right;
  align-items: right;
}
.annotations-div-trb {
  pointer-events: none;
  position: absolute;
  padding: 6px;
  right: 0;
  width: 100%;
  height: 100%;
  color: black;
  z-index: 2;
  text-align: right;
  align-items: right;
}
.annotations-div-bl {
  pointer-events: none;
  position: absolute;
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 3;
}
.annotations-div-blb {
  pointer-events: none;
  position: absolute;
  padding: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  color: black;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2;
}
.annotations-div-br {
  pointer-events: none;
  position: absolute;
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  z-index: 3;
}
.annotations-div-brb {
  pointer-events: none;
  position: absolute;
  padding: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  color: black;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  z-index: 2;
}
</style>